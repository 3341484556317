/*
  This is a hack needed to override react-vis styles
  https://github.com/uber/react-vis/issues/1215
*/
.rv-xy-plot__axis__title.no-data text {
  font-size: 24px;
}

/*
  Same issue here - but instead of overriding the default class for all charts
  We have created an alt class that can be used when needed on ChartLabel
*/
.alt-y-axis-label text {
  font-size: 13px;
  fill: #000000;
}